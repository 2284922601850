
import * as imgixRuntime$P6GgrZzNhm from '/home/runner/work/coassemble-author/coassemble-author/frontend/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as builderioRuntime$PVqlqsGfg8 from '/home/runner/work/coassemble-author/coassemble-author/frontend/src/services/builderio.image.js'

export const imageOptions = {
  "screens": {
    "xs": 375,
    "sm": 600,
    "md": 900,
    "lg": 1200,
    "xl": 1600,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgix",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgix']: { provider: imgixRuntime$P6GgrZzNhm, defaults: {"baseURL":"https://coassemble-marketing.imgix.net/assets/transform"} },
  ['builderio']: { provider: builderioRuntime$PVqlqsGfg8, defaults: undefined }
}
        