export function getImage(src, { modifiers } = {}) {
    const operations = [];
    if (modifiers) {
        Object.keys(modifiers).forEach(key => {
            const value = modifiers[key];
            if (value) {
                operations.push(`${key}=${value}`);
            }
        });
    }

    const operationsString = operations.join('&');
    return {
        url: `${src}?${operationsString}&format=webp&quality=90`,
    };
}
